<template>
    <audio ref="successAudio" :src="successSrc" @ended="onEnded"></audio>
    <audio ref="errorAudio" :src="errorSrc" @ended="onEnded"></audio>
  </template>
  
  <script>
  export default {
    data() {
      return {
        successSrc: './success.mp3', // 相对于public目录
        errorSrc: './error.mp3', // 相对于public目录
      };
    },
    methods: {
      play(success) {
        if (success) {
          this.$refs.successAudio.currentTime = 0; // 重置播放时间
          this.$refs.successAudio.play();
        } else {
          this.$refs.errorAudio.currentTime = 0; // 重置播放时间
          this.$refs.errorAudio.play();
        }
      },
      pause() {
        if (this.$refs.successAudio) {
          this.$refs.successAudio.pause();
        }
        if (this.$refs.errorAudio) {
          this.$refs.errorAudio.pause();
        }
      },
      onEnded() {
        // 音频播放结束时的回调
        this.$emit('ended');
      }
    }
  }
  </script>