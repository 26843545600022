import { createRouter, createWebHistory } from 'vue-router';
import MemberManagement from '../views/MemberManagement.vue';

const routes = [
  {
    path: '/members',
    name: 'MemberManagement',
    component: MemberManagement
  },
  {
    path: '/',
    redirect: '/members' // 确保重定向到会员管理页面
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;